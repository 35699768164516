import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "welcome"
    }}>{`Welcome`}</h1>
    <p>{`Welcome to Capella’s developer resource center! This page includes important
information and guides for getting started with Capella’s API. To learn
more about Capella Space and our groundbreaking new SAR satellite constellation,
please visit `}<a parentName="p" {...{
        "href": "https://www.capellaspace.com/"
      }}>{`capellaspace.com`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      